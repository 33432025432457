import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'
import { AngularFireFunctions } from '@angular/fire/functions'
import { uniq } from 'lodash'
import { Observable, combineLatest, of } from 'rxjs'
import { map, switchMap, auditTime } from 'rxjs/operators'
import { Notifications } from '../../../models'
import { NotificationItems } from '../../../models'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  collection: AngularFirestoreCollection<Notifications>
  collection2: AngularFirestoreCollection<NotificationItems>
  list: Observable<Notifications[]>
  list2: Observable<NotificationItems[]>

  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
  ) {
    this.collection = afs.collection<Notifications>('notifications')
    this.collection2 = afs.collection<NotificationItems>('notifications_items')
    //all schedules
    this.list = this.collection.valueChanges().pipe(
      map(schedule => schedule.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    )
    //all schedules items
    this.list2 = this.collection2.valueChanges()
  }
  //fetch all notifications
  getAllNotifications(): Observable<Notifications[]> {
    return this.list
  }
  //all nontifications items
  getFullNotificationItems(): Observable<NotificationItems[]> {
    return this.list2
  }
  //notifications by notification id 
  getAllNotificationItems(notificationId): Observable<any[]> {
    return this.afs.collection<any>('notifications_items', ref => ref.where('notification_id', '==', notificationId))
    .valueChanges()
    .pipe(
      map(sItems => {
        return sItems
      })
    )
  }

  //get all notification by org
  getAllNotificationByGroup(): Observable<any[]> {
    return this.afs.collection<any>('notifications', ref => ref.where('notification_for', '==', 2)).valueChanges()
    .pipe(auditTime(1000),
      switchMap(notifications => {
        const notificationIds = uniq(notifications.map(notification => notification.id))

        return notificationIds.length === 0 ? Observable.of([]):combineLatest(
          of(notifications),
          // check for orgs
          combineLatest(
            notificationIds.map(nId =>
              this.afs.collection<NotificationItems>('notifications_items', ref => ref.where('notification_id', '==', nId)).valueChanges().pipe(
                map(nItems => nItems.length > 0 ? nItems:[])
              )
            )
          ),
        )
      }),
      map(([notifications, nItems]) => {
        return (notifications == undefined || notifications.length == 0) ? []:notifications.map(notification => {
          return {
            ...notification,
            nItems: nItems.find(nItem => (nItem[0] != undefined && nItem[0].notification_id === notification.id))
          }
        })
      })
    )
  }

  //fetch selected notification
  fetchNotification(notificationId): Observable<any[]> {
    return this.afs.collection<any>('notifications', ref => ref.where('id', '==', notificationId))
    .valueChanges()
    .pipe(
      map(sItems => {
        return sItems
      })
    )
  }

  //fetch notification by notification created ids
  matchNotificationIds(matchId, nFor): Observable<any[]> {
    return this.afs.collection<any>('notifications', ref => 
    ref
    .where('notification_type', '==', 4)
    .where('notification_for', '==', nFor)
    .where('notification_created_ids', 'array-contains', matchId)
    )
    .valueChanges()
    .pipe(
      map(sItems => {
        return sItems
      })
    )
  }

  //notifications by notification id 
  checkForNItems(notificationId, deviceId): Observable<any[]> {
    return this.afs.collection<any>('notifications_items', ref => 
    ref
    .where('notification_id', '==', notificationId)
    .where('device_id', '==', deviceId)
    )
    .valueChanges()
    .pipe(
      map(sItems => {
        return sItems
      })
    )
  }

  //notifications by notification id 
  fetchNItemsByDevice(deviceId): Observable<any[]> {
    return this.afs.collection<any>('notifications_items', ref => 
    ref.where('device_id', '==', deviceId).where('sent_status', '==', true).where('stop_snooze', '==', false)
    )
    .valueChanges()
    .pipe(
      map(nItems => {
        return nItems
      })
    )
  }

  //create notification
  add(item: Notifications, notificationItem=[], itemType): Promise<void> {
    item.id = this.afs.createId()
    item.createdAt = new Date()

    // return this.collection.doc(item.id).set(item);
    
    if(this.collection.doc(item.id).set(item)) {
      for (const [key, value] of Object.entries(notificationItem)) {
        //store device id's to notified
        this.addItems({
          id: this.afs.createId(),
          createdAt: new Date(),
          notification_id: item.id,
          device_id: value,
          sent: 0,
          sent_status: false,
          stop_snooze: false,
          last_notification_sent_at: null
        })

      }
      return;
    }
  }
  //create notification items
  addItems(item: NotificationItems): Promise<void> {
    return this.collection2.doc(item.id).set(item)
  }
  //update notification
  updateNotification(notificationId: string, item: any, notificationItem=[], itemType: number, newItems: boolean) {
    for (const [key, value] of Object.entries(notificationItem)) {
      //store device id's to notified
      this.addItems({
        id: this.afs.createId(),
        createdAt: new Date(),
        notification_id: notificationId,
        device_id: value,
        sent: 0,
        sent_status: false,
        stop_snooze: false,
        last_notification_sent_at: null
      })

    }
    
    return this.collection.doc(notificationId).update(item)
  }
  
  //update notification item
  updateNotificationItem(nItemId: string, item: any) {    
    return this.collection2.doc(nItemId).update(item)
  }
  //remove notification
  deleteNotification(notificationId: string) {
    return this.collection.doc(notificationId).delete()
  }
  //remove notification items
  deleteNotificationItems(nItemId: string) {
    return this.collection2.doc(nItemId).delete()
  }

}
