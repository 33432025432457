import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DefaultSchedules } from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class DefaultSchedulingService {

  collection: AngularFirestoreCollection<DefaultSchedules>
  list: Observable<DefaultSchedules[]>

  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
  ) {
    this.collection = afs.collection<DefaultSchedules>('default_schedules')

    //all schedules
    this.list = this.collection.valueChanges().pipe(
      map(schedule => schedule.sort((a, b) => a.week_day > b.week_day ? 1 : -1))
    )

  }

  getDefaultScheduleByDeviceId(deviceId) {
    return this.collection.ref.where('device_id', '==', deviceId).get();
  }

  //fetch schedule by device
  getAllDefaultSchedulesByDevice(deviceId, weekDay) {
    return this.collection.ref.where('device_id', '==', deviceId).where('week_day', '==', weekDay).orderBy('start_time', 'asc').get();
  }

  //update schedule
  updateSchedule(scheduleId: string, item: any) {
    return this.collection.doc(scheduleId).update(item)
  }

  //update schedule
  deleteSchedule(scheduleId: string) {
    return this.collection.doc(scheduleId).delete()
  }

  //create schedule
  add(item: DefaultSchedules): Promise<void> {
    this.collection.add(item);
      // item.id = this.afs.createId()
      // console.log({item})
      // item.createdAt = new Date()
      return;
  }

}
