export const deviceModes = [
  {id: 1, name: 'AUTO'},
  {id: 2, name: 'ECONOMY'},
  {id: 3, name: 'TIMED'},
  {id: 5, name: 'Stand By'},
];

export const NoticationType = {mode: 2, bulb_hours: 3, max_dose: 4};
export const NoticationTypeInverse = {2: 'Device mode has changed', 3: 'Bulb Hours', 4: 'Max dose has been reached'};

export interface Notification {
  label?: any;
  hasChildren: boolean;
  type?: any;
  rows?: Array<SubNotification>;
  value?: any;
  option?: SubNotification;
}

export interface SubNotification {
  label: any;
  value: any;
  type: any;
}

export const NotificationTypes: Array<Notification> = [
  {
    label: NoticationTypeInverse[NoticationType.mode],
    hasChildren: true,
    rows: [
      {type: NoticationType.mode, label: deviceModes[0].name, value: deviceModes[0].id},
      {type: NoticationType.mode, label: deviceModes[1].name, value: deviceModes[1].id},
      {type: NoticationType.mode, label: deviceModes[2].name, value: deviceModes[2].id},
    ],
  },
  {
    label: NoticationTypeInverse[NoticationType.bulb_hours],
    hasChildren: true,
    rows: [
      {type: NoticationType.bulb_hours, label: '2700 Hours', value: 2700},
      {type: NoticationType.bulb_hours, label: '3000 Hours', value: 3000},
    ],
  },
  {
    hasChildren: false,
    type: NoticationType.max_dose,
    label: NoticationTypeInverse[NoticationType.max_dose],
    value: 1,
  },
];

export const dataUpdateTime = 5;

export const sysAdminRoles = [
  {name: 'System Admin', value: 3},
  {name: 'Org Admin', value: 1},
  {name: 'Org Manager', value: 2},
  {name: 'Operator', value: 0},
];

export const orgAdminRoles = [
  {name: 'Org Admin', value: 1},
  {name: 'Org Manager', value: 2},
  {name: 'Operator', value: 0},
];

export const orgManager = [
  {name: 'Org Manager', value: 2},
  {name: 'Operator', value: 0},
];

export const scheduleTypes = [
  {id: 1, name: 'AUTO'},
  {id: 2, name: 'ECONOMY'},
  {id: 3, name: 'TIMED'},
  {id: 5, name: 'Standby'},
  {id: 15, name: 'Force 15'},
  {id: 30, name: 'Force 30'},
  {id: 45, name: 'Force 45'},
  {id: 60, name: 'Force 60'},
];
export const _scheduleBy = (value, name = false) => scheduleTypes.find((i) => (name ? i.name == value : i.id == value));

export const weekDays = {
  'Sunday': 1,
  'Monday': 2,
  'Tuesday': 3,
  'Wednesday': 4,
  'Thursday': 5,
  'Friday': 6,
  'Saturday': 7,
}

export const weekDaysReverse = [
  {id: 1, name: 'Sunday'},
  {id: 2, name: 'Monday'},
  {id: 3, name: 'Tuesday'},
  {id: 4, name: 'Wednesday'},
  {id: 5, name: 'Thursday'},
  {id: 6, name: 'Friday'},
  {id: 7, name: 'Saturday'},
]

export const firmwareScheduleTypes = [
  {id: 1, name: 'AUTO'},
  {id: 2, name: 'ECONOMY'},
  {id: 3, name: 'TIMED'},
  {id: 5, name: 'Standby'}
];

export interface DefaultSchedule {
  id: string,
  mode: number,
  device_id: string,
  start_time: string,
  week_day: number,
  minValue: number,
  maxValue: number,
  timezone: string
}