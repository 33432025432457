import { firestore } from 'firebase'

export const convertToDate = (date: firestore.Timestamp | Date): Date => {
  if (date instanceof firestore.Timestamp) {
    return date.toDate()
  }
  return date
}

export const getUTCMidnight = (): Date => {
  const now = new Date()
  now.setUTCHours(0, 0, 0, 0)
  return now
}

export const getUTCMinuteOfDate = (date: Date): number => {
  return (date.getUTCHours() * 60) + date.getUTCMinutes()
}
